import {Controller} from "stimulus"

export default class MultiElementItemGalleryController extends Controller {
  static targets = ["item", "image", "linkImage", "titleLink"]

  initialize() {
    this.initBinding()
    this.initClass()
  }

  initBinding() {
    this.numberOfItems = this.numberOfItemTargets()
  }

  initClass() {
    switch (this.numberOfItems) {
      case 1:
        this.element.className = "relative grid gap-x-8 gap-y-12 grid-cols-1 mb-8"
        break
      case 2:
      case 4:
        this.element.className = "relative grid gap-x-8 gap-y-12 grid-cols-1 md:grid-cols-2 mb-8"
        break
      default:
        this.element.className = "relative grid gap-x-8 gap-y-12 grid-cols-1 md:grid-cols-3 mb-8"
    }

    this.itemTargets.forEach((item, index) => {
      let className = "h-fit flex flex-col items-center"
      if (this.numberOfItems === 5 && (index === 3 || index === 4)) { className = "h-fit flex flex-col items-center md:translate-x-[calc(50%+1rem)]" }
      if (this.numberOfItems === 7 && (index === 6)) { className = "h-fit flex flex-col items-center md:translate-x-[calc(100%+1rem)]" }
      if (this.numberOfItems === 8 && (index === 6 || index === 7)) { className = "h-fit flex flex-col items-center md:translate-x-[calc(50%+1rem)]" }
      item.className = className
    })
    
    this.setElementsClassname(this.linkImageTargets, "w-full max-h-[700px] aspect-[1.5] flex flex-col justify-center items-center pb-4")
    this.setElementsClassname(this.imageTargets, "w-full h-full object-cover rounded-md border-solid border-x border-y border-gray-200")
    this.setElementsClassname(this.titleLinkTargets, "flex w-full text-center font-bold underline text-[#035dd9] decoration-blue-200 hover:decoration-[#035dd9]")
  }

  setElementsClassname(elements, classname){
    elements.forEach((element) => { element.className = classname })
  }
  
  numberOfItemTargets() {
    return this.itemTargets.length
  }
}
