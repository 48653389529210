import { Controller } from "stimulus";

export default class extends Controller {
    connect() {
    }

    linkClicked(event) {
        const ga4 = {
            "event": "user_event",
            "event_name": "click",
            "context": "facet_pills",
        };
        dataLayer.push(ga4);
    }
}
