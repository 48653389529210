import { Controller } from "stimulus"
import "@accessible360/accessible-slick"
import $ from "jquery"
export default class extends Controller {
  static targets = ["items"]
  static values = {
    title: { type: String, default: "" },
    imageUrls: { type: Array, default: [] },
    itemUrl: { type: String, default: "" }
  }

  initialize() {
    this.initHTML()
    this.initCarousel()
  }

  initHTML() {
    this.itemsTarget.innerHTML = this.itemsHTML()
  }

  itemsHTML() {
    return this.imageUrlsValue.map((url, index) => this.itemHTML(url, index)).join('')
  }

  itemHTML(url, index) {
    return `
      <a href="${this.itemUrlValue}" class="flex justify-center items-center max-w-[850px] w-full">
        <img
          alt="${this.titleValue} - Preview ${index + 1}"
          src="${url}" class="w-full object-cover rounded-lg"
          loading="lazy"
        >
      </a>
    `
  }

  initCarousel() {
    let container = $(this.itemsTarget);
    container.slick({
      infinite: false,
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: '<button class="slick-prev fa fa-chevron-left !top-1/2 !left-0 -translate-x-1/2 -translate-y-1/2" aria-label="Previous" type="button"></button>',
      nextArrow: '<button class="slick-next fa fa-chevron-right !top-1/2 !right-0 translate-x-1/2 -translate-y-1/2" aria-label="Next" type="button"></button>',
    });
  }
}
